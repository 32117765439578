import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, Warning, Extern, FirstP, Emoji } from "./../../../components/helpers.js"
import { SPIFFS } from "./../../../components/definitions.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <Warning>This post explains the reasoning and philosophy behind the ESP8266 IoT Framework.
        Since the framework is evolving over time, some of this post might be outdated. Find the latest
            on <Extern href="https://github.com/maakbaas/esp8266-iot-framework">GitHub</Extern>.</Warning>

        <FirstP>Most projects I do with the ESP8266 require an internet connection. If this would not be needed I would probably 
            use something else like an Arduino for that project. Most of these Internet of Things (IoT) projects require a common set of 
            functions to deal with for instance WiFi connections and HTTP(S) requests. 
            For some of this I already have a few snippets of code, but these are neither robust nor elegant. 
            Therefore I decided to develop a custom framework for the ESP8266. Here I will discuss its requirements.</FirstP>

        <p>Before we go into the details, let me start off by listing the basic principles used in developing this framework:</p>

        <ol>
            <li>The framework will be built upon the ESP8266 Arduino libraries</li>
            <li>The framework will not include any hardware functionality. If I start a new project, I still want to write the code for that project myself, from scratch.</li>
            <li>The framework must be fully self-contained for easy deployment. Using the <SPIFFS /> for files needed by the framework is not allowed.</li>
            <li>There must be a strict split between the ESP8266 application and the web interface through an API.</li>
        </ol>

        <p>In short, the framework must be unobtrusive, easy to deploy, and the web interface should be easy to modify and expand for different
            projects <Emoji e="😎" />. With that out of the way, let's see what functions the framwork will cover.</p>

        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Architecture" />
        <Caption>The proposed architecture and functionality of the framework shown in blue</Caption>

        <p>The framework will consist of five main parts. A web server including the interface it's serving, a WiFi manager, a configuration manager and classes for HTTP requests and OTA updates.
            Each of these parts will be briefly introduced and the most important parts will be detailed out in a follow-up post.
        </p>

        <h3>Web Server</h3>

        <p>A web server is required to present a web interface which is needed to configure WiFi and other settings from the browser. 
            This web interface will be developed in React, and 
            communicate with the ESP8266 through an API. To be able to get and set information from this API, the web server class will interact
            with the other framework modules.
        </p>

        <h3>WiFi Manager</h3>

        <p>The function of the WiFi manager is to help the user connect to a WiFi network. If no known network is found the ESP8266 should start a hotspot
            with a captive portal in which the network settings can be changed. WiFi information will be preserved in memory so that the ESP8266 will connect
            automatically in the future.</p>

        <h3>Configuration Manager</h3>

        <p>For whatever function a device with an ESP8266 might have, it could be that you want the user to change some settings or parameters. 
            For instance with my <a href="/linear-calendar-clock/logs/display-modes/">linear clock</a>, The user could want to change the display mode or the colors of the clock.
            The configuration manager will aim to provide a method to modify parameters from the broswer which will be accessible from the software application.
        </p>

        <h3>HTTPS Requests</h3>

        <p>
            Fetching or posting data to the internet is one of the core tasks of an IoT device. Doing so over HTTP is implemented quite well in the default
            ESP8266 Arduino libraries, but for HTTPS requests things are less straightforward. This class will aim to implement arbitrary HTTPS requests in 
            a secure way, without requiring any specific certificates or fingerprints to be hard coded in the application.
        </p>

        <h3>OTA Updates</h3>

        <p>Finally the framework will provide a function to update the firmware on the ESP8266 from the browser. This can be useful when you give or sell your
            products to somebody else and need to update the software later on.
        </p>
       
    </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "framework.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
